import "./styles.css";
import { background, dailyImages } from "./img/index";
import { useState } from "react";
import Modal from "react-modal";

const modalStyle = {
  content: {
    top: "2%",
    left: "50%",
    right: "auto",
    bottom: "2%",
    marginRight: "-50%",
    transform: "translate(-50%, 0)",
    maxWidth: "95%",
  },
};
const days = Array.from(Array(24).keys(), (n) => n + 1);

export default function App() {
  const [open, setOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState({});

  async function openDay(e, day) {
    const date = new Date(2023, 11, day);
    const today = new Date();
    if (today > date) {
      localStorage.setItem(day, true);
      setSelectedDay(dailyImages[day - 1]);
      setOpen(true);
    } else {
      e.target.classList.add("Shake");
      setTimeout(() => {
        e.target.classList.remove("Shake");
      }, 300);
    }
  }

  function onCloseModal() {
    setOpen(false);
  }

  return (
    <div className="App">
      <Modal
        ariaHideApp={false}
        isOpen={open}
        onRequestClose={onCloseModal}
        style={modalStyle}
      >
        <div className="ModalContent">
          <img src={selectedDay.src} />
          <h2>{selectedDay.header}</h2>
          <p>{selectedDay.description}</p>
        </div>
        <button className="Button" onClick={onCloseModal}>
          X
        </button>
      </Modal>
      <div className="BgDiv">
        <img className="BgImg" src={background} />
      </div>
      <div className="GridContainer">
        {days.map((day) => (
          <div
            key={"day-" + day}
            onClick={(e) => openDay(e, day)}
            className={`GridItem ${localStorage[day] ? "Opened" : ""}`}
          >
            {day}
          </div>
        ))}
      </div>
    </div>
  );
}
