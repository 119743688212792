import background from "./bg.jpeg";
import one from "./1.jpeg";
import two from "./2.jpeg";
import three from "./3.jpeg";
import four from "./4.jpeg";
import five from "./5.jpeg";
import six from "./6.jpeg";
import seven from "./7.jpeg";
import eight from "./8.jpeg";
import nine from "./9.jpeg";
import ten from "./10.jpeg";
import eleven from "./11.jpeg";
import twelve from "./12.jpeg";
import thirteen from "./13.jpeg";
import fourteen from "./14.jpeg";
import fifteen from "./15.jpeg";
import sixteen from "./16.jpeg";
import seventeen from "./17.jpeg";
import eighteen from "./18.jpeg";
import nineteen from "./19.jpeg";
import twenty from "./20.jpeg";
import twentyone from "./21.jpeg";
import twentytwo from "./22.jpeg";
import twentythree from "./23.jpeg";
import twentyfour from "./24.jpeg";

const dailyImages = [
  {
    src: one,
    header: "Zebra",
    description:
      "Zebror är snabba djur som bor i Afrika och den afrikanska savannen. De har ränder på kroppen som hjälper dem att kamouflera i sin miljö, vilket hjälper dem att vara säkra från rovdjur. Zebror kan vara svarta, vita eller sandfärgade med vita eller ljusgrå ränder på sin päls. Alla zebror har ett alldeles eget mönster ungefär som ett fingeravtryck. Zebror är snabba och smarta, men de måste se upp för lejon, hyenor och vargar som vill äta dem.",
  },
  {
    src: two,
    header: "Lax",
    description:
      "Laxar är fiskar som bor i havet och floder. Laxar simmar från havet till floden där de föddes för att få barn. Det är en lång och svår resa. De måste akta sig för säl, örn och björn. De måste hoppa över vattenfall och forsar. Laxar kan göra otroliga saker. De kan känna igen sin hemflod med hjälp av sitt luktsinne. De kan anpassa sig till både sött och salt vatten. De kan simma snabbt och hoppa högt. De kan leva i flera år och växa sig stora. Laxar är vackra och spännande fiskar.",
  },
  {
    src: three,
    header: "Tiger",
    description:
      "Tigrar är stora och vackra katter som bor i Asien. De har ränder på sin päls och kan vara orange, vit eller svart. Tigrar är också väldigt starka och kan springa snabbt. De är det största katt-djuret i världen och kan bli upp till 3 meter långa. Tigrar äter mestadels kött som hjortar, vildsvin och apor. De är också kända för att ha en mycket hög röst som de använder för att kommunicera med varandra. Tigrar är tyvärr utrotningshotade och det finns bara några tusen kvar i världen.",
  },
  {
    src: four,
    header: "Älg",
    description:
      "Älgar är stora djur som bor i skogen. De har stora öron, en lång mun och en puckel på ryggen. Älgar kan vara mörkbruna, nästan svarta eller ljust bruna. De är också väldigt bra simmare. Älgar är det största hjortdjuret i världen och kan bli upp till 2,3 meter höga. Tjurarna (hanarna) är ungefär 20% större än korna (honorna) och har grövre nackar och större hakskägg. Älgar äter mestadels växter som blad, kvistar och gräs. De är också kända som skogens konung och är Sveriges nationaldjur.",
  },
  {
    src: five,
    header: "Padda",
    description:
      "Paddor är små djur som bor i dammar och sjöar. De har en mjuk och läderaktig hud och kan klättra på murar och i häckar. Paddor är också nattaktiva och patrullerar ett litet område. De kan vara grå, bruna eller gröna. Paddor är inte samma sak som grodor, men de är båda groddjur. Paddor är också kända för att ha en mycket speciell sång som de sjunger på natten.",
  },
  {
    src: six,
    header: "Flodhäst",
    description:
      "Flodhästar är stora och roliga djur som bor i vattnet. De har en mjuk och känslig hud och kan simma jättebra. Flodhästar kan vara grå eller brunaktiga och har stora tänder som de använder för att visa att de är starka. De äter mestadels växter som gräs och blad och kan äta upp till 50 kg växter varje dag! Flodhästar är också kända för att ha det största gapet av alla däggdjur i världen. De kan vara farliga om de blir arga, så det är bäst att hålla sig på avstånd.",
  },
  {
    src: seven,
    header: "Bäver",
    description:
      "Bävrar är djur som bor i vattnet och bygger sina hem av trä. De har en mjuk och känslig hud och kan simma jättebra. Bävrar kan vara bruna och ha en svart svans gjord av späck som kan bli ca 30 cm lång. Bävrar är också stora gnagare och kan bli upp till 120 cm långa. De äter mestadels växter som gräs och bark från träd. Bävrar är också kända för att ha tänder som aldrig slutar växa, så de gnager på träd för att hålla sina tänder korta.",
  },
  {
    src: eight,
    header: "Gorilla",
    description:
      "Gorillan är ett stort djur som bor i Afrika. Den har stora kindtänder och käkmuskler. En gorilla kan vara svart eller grå och ha en silvergrå päls när den blir gammal. Gorillan är också störst av människoaporna och kan bli upp till 1,8 meter hög. Gorillor lever i små grupper med 5-15 djur och ledaren skyddar sin flock mot faror som hotar hans familj. Gorillor är vegetarianer och äter bananer, frukt, blad, grenar, pinnar, bark, fröer, svamp men också myror och termiter.",
  },
  {
    src: nine,
    header: "Räv",
    description:
      "Rävar är djur som bor i skogen. De är riktigt listiga och har bra syn, hörsel och luktsinne. Rävar har en mjuk rödbrun päls och älskar att äta andra djur. De bor i en grotta som har grävts av kaniner eller grävlingar. Rävar jagar på natten men är både natt- och dagdjur. Deras diet består av möss, kaniner, fiskar, grodor, bär och insekter. Individerna lever ensamma förutom under parningstiden. Valparna matas genom att föräldrarna kräks upp halvsmält mat. Rävar är också kända för att vara en symbol för listighet och snabbhet i många kulturer.",
  },
  {
    src: ten,
    header: "Elefant",
    description:
      "Elefanter är jättestora djur som bor i Afrika och Asien. De har en lång snabel som de använder för att äta och dricka. Elefanter kan vara grå eller brunaktiga och har stora öron som hjälper dem att höra bra. De är också väldigt starka och kan bära tunga saker med sin snabel. Elefanter är det största landdjuret i världen och kan bli upp till 3 meter höga och väga upp till 6 ton. Elefanter är också växtätare och äter mestadels gräs, blad och frukt.",
  },
  {
    src: eleven,
    header: "Sköldpadda",
    description:
      "Sköldpaddor är djur som bor i vattnet eller på land. De har en mjuk och känslig hud och kan simma jättebra. Sköldpaddor kan vara bruna, gröna eller gråa. De har också ett hårt skal på kroppen som skyddar dem. Landsköldpaddor kan dra in huvud, ben och svans mellan ryggskölden och bukskölden när en fara hotar. Det finns omkring 260 olika arter av sköldpaddor som lever på land och i vatten. Sköldpaddor är reptiler och har funnits på jorden i flera miljoner år. De är också kända för att vara tillbakadragna och blyga till sin natur.",
  },
  {
    src: twelve,
    header: "Valross",
    description:
      "Valrossen är ett stort djur som bor i ishavet i Arktis. De har en tjock hud och kan ha upp till 15 cm späck för att hålla värmen. Valrossar är släkt med sälar och är inte en val. De är duktiga på att simma men rör sig klumpigt på land. Valrossar kan dyka 100 meter och vara under vattnet i 10 minuter. De lever i stora flockar med tusentals djur under parningstiden. Hanar med stora betar står högt i rang (har hög status). Valrossar äter främst musslor men äter även snäckor, kräftdjur, fisk och sälar.",
  },
  {
    src: thirteen,
    header: "Rödhake",
    description:
      "Rödhaken är en liten fågel som gärna bor nära människor, till exempel i trädgårdar. Den har sitt eget revir med bestämda gränser som den försvarar mot andra rödhakar. Rödhaken är en liten brun fågel med rostorange bröst. Hannen och honan är lika medan ungfåglarna är bruna med fläckar och saknar rött bröst. Rödhaken är också känd för sitt utseende, det röda bröstet, det mörka pepparkornsögat och den nätta kroppsbyggnaden. Rödhaken är en orädd fågel, som hoppar på marken med långa smala ben och karakteristiska knixanden.",
  },
  {
    src: fourteen,
    header: "Giraff",
    description:
      "Giraffer är jättestora djur som bor i Afrika. De har en lång hals som kan vara över 2 meter lång och används för att äta blad från höga träd. Giraffer kan bli upp till 5-6 meter höga och väga upp till 2 ton. De har också en mjuk och fläckig päls som kan vara brun eller gulaktig. Giraffer är också kända för att vara de längsta djuren i världen. De lever i flockar och är växtätare, vilket betyder att de äter mestadels gräs och blad. Giraffer är också kända för att ha två horn på huvudet som är täckta av hår. ",
  },
  {
    src: fifteen,
    header: "Get",
    description:
      "Getter är djur som bor på bondgårdar. De har fyra ben och en mjuk päls som kan vara vit, brun eller svart. Getter har också horn på huvudet som de använder för att skydda sig mot rovdjur. Getter är också kända för att äta gräs och blad. De är också väldigt sociala djur och gillar att leka med varandra. Getter kan också ge mjölk som människor kan dricka eller använda för att göra ost. ",
  },
  {
    src: sixteen,
    header: "Gris",
    description:
      "Grisar är smarta och snälla djur som bor på bondgårdar. De är väldigt sociala djur och gillar att leka med varandra. Grisar är också mycket intelligenta och anses vara smartare än hundar. Mamma gris kallas för So eller Sugga. Pappa gris kallas för Galt. Grisens barn kallas för kulting. Grisar är också väldigt renliga djur och sover på ett ställe och bajsar och kissar på ett annat ställe.",
  },
  {
    src: seventeen,
    header: "Isbjörn",
    description:
      "Isbjörnar är stora och vita björnar som bor i Arktis. De har en tjock päls som hjälper dem att hålla sig varma i kylan. Isbjörnar är också väldigt bra simmare och kan simma upp till 50 mil i det iskalla vattnet mellan packisen och land. De äter mestadels kött som sälar, valar och vikare. Isbjörnar är också hotade av uppvärmning och gifter. Det finns cirka 22 000-31 000 isbjörnar i världen.",
  },
  {
    src: eighteen,
    header: "Alligator",
    description:
      "Alligatorer är stora och läskiga djur som bor i vattnet. De har en lång nos och stora tänder som de använder för att fånga fisk och andra djur. Alligatorer kan vara gröna eller bruna och har en tjock hud som skyddar dem från faror. De lever i träsk och våtmarker och bidrar till naturen genom att skapa hål som ger näring till växtligheten under torra perioder. Alligatorer är också kända för att ha en reglerande effekt på djurlivet i de områden där de lever.",
  },
  {
    src: nineteen,
    header: "Varg",
    description:
      "Vargar är stora och vackra djur som bor i skogen. De är också sociala djur som lever i flockar. Vargar är köttätare och jagar i flock och samarbetar när de jagar. De tar helst större djur som hjortar, älg, myskoxe och bison. I Sverige äter de helst älgar men också rådjur, renar, vildsvin, harar och bävrar. Vargar är också mycket intelligenta och anses vara smartare än hundar. Mamma varg kallas för tik och pappa varg kallas för hane. Vargens barn kallas för valpar. Vargar är också en symbol för listighet och snabbhet i många kulturer.",
  },
  {
    src: twenty,
    header: "Brunbjörn",
    description:
      "Brunbjörnen är ett stort djur som lever i skogen. Den är brun till färgen och har stora tassar. Brunbjörnen är allätare, vilket betyder att den äter både växter och kött. Men faktum är att den mest äter växter och småkryp. Brunbjörnen är vårt största landlevande rovdjur. Brunbjörnen undviker vanligtvis människor.",
  },
  {
    src: twentyone,
    header: "Noshörning",
    description:
      "Noshörningen är ett stort djur som har ett stort horn på nosen. Det finns fem olika typer av noshörningar som lever i Asien och Afrika. Noshörningen är ett hotat djur och det finns färre än 15 000 kvar i världen. Noshörningen är en växtätare och äter mestadels gräs. Noshörningen har en tjock hud som skyddar den från skador och insekter. Noshörningen är ett av de mest imponerande djuren i världen och är en symbol för styrka och uthållighet.",
  },
  {
    src: twentytwo,
    header: "Lejon",
    description:
      "Lejon är stora och starka djur som bor i Afrika. De har stora tänder och klor som de använder för att jaga andra djur. Lejonen gillar att leva i grupper som kallas “pride”. Hanlejonen har en stor och fluffig man som gör dem extra coola. Lejonen är utrotningshotade, vilket betyder att det finns allt färre av dem kvar i världen. Men det finns fortfarande några kvar som lever i naturen och som vi måste skydda.",
  },
  {
    src: twentythree,
    header: "Vithövdad havsörn",
    description:
      "Vithövdade havsörnen är en stor och mäktig fågel som bor i Nordamerika. Den har en mörkbrun fjäderdräkt och ett vitt huvud och stjärt. Näbben, iris och fötterna är gula. En vuxen hanhavssörn kan bli ungefär 90 centimeter lång och ha ett vingspann på ungefär 200 centimeter. Honorna är större och kan mäta 110 cm på längden och ha ett vingspann på uppemot 250 centimeter. Vithövdade havsörnar är fridlysta sedan 1940 och är USA:s nationalfågel.",
  },
  {
    src: twentyfour,
    header: "Lodjur",
    description:
      "Lodjur är ett stort och mäktigt djur som bor i skogar i Europa och Asien. De har en kort kropp med långa ben, runda tassar och stora trampdynor. Hanarna kan väga mellan 20 och 30 kilogram och honorna mellan 15 och 20 kilogram. Lodjur har en mörkbrun päls med mörka fläckar och vita teckningar runt ögonen och på hakan. De har också en karaktäristisk tofs högst upp på öronen. Lodjur är utmärkta jägare och använder sig av syn, hörsel och luktsinne för att hitta byten. De är fridlysta sedan 1940 och är ett vitt spritt kattdjur som inte anses vara hotat.",
  },
];

export { background, dailyImages };
